<template>
  <div class="container py-4">
    <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold">New Task</h1>

        <form @submit.prevent="handleSubmit" class="p-2">
          <div class="mb-3">
            <label for="taskTitle" class="form-label">Title</label>
            <input
              v-model="taskTitle"
              type="text"
              class="form-control"
              id="taskTitle"
            />
          </div>
          <div class="mb-3">
            <label for="taskDetail" class="form-label">Details</label>
            <textarea
              v-model="taskDetails"
              class="form-control"
              id="taskDetail"
              rows="5"
            ></textarea>
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import capitalizeWords from "@/composables/Capitalize";
import { useStore } from "vuex";

// firestore
import { db } from "@/firebase/config";
import { collection, addDoc } from "firebase/firestore";

export default {
  name: "AddTask",

  setup() {
    const router = useRouter();
    const taskTitle = ref("");
    const taskDetails = ref("");

    // load store
    const store = useStore();
    // grab state
    const projectState = store.state.projectState;
    // grab user stare
    const user = store.state.user;

    onMounted(() => {
      if (!projectState) {
        router.push("/");
      }
    });

    const handleSubmit = async () => {
      try {
        taskTitle.value = capitalizeWords(taskTitle.value);
        let upDatedTaskDetails = taskDetails.value.replace(/\r?\n/g, "<br>");
        let newTask = {
          title: taskTitle.value,
          details: upDatedTaskDetails,
          project: projectState.id,
          user: user.uid,
          complete: false,
        };

        const colRef = collection(db, "tasks");

        await addDoc(colRef, newTask);

        //redirect
        router.push("/show");
      } catch (error) {
        if (error.code === "permission-denied") {
          router.push({ name: "Login" });
        } else {
          //handle other non-permission errors here
        }
      }
    };

    return { taskTitle, taskDetails, handleSubmit };
  },
};
</script>

<style>
</style>