// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAqJp6YmvrZYFFZ9DJTRxIg2I3zwq7zZcs",
  authDomain: "todo-i-ng.firebaseapp.com",
  projectId: "todo-i-ng",
  storageBucket: "todo-i-ng.appspot.com",
  messagingSenderId: "723132615809",
  appId: "1:723132615809:web:4be604a8527339a9d8d180"
};

// Initialize Firebase
initializeApp(firebaseConfig);

// get firebase services
const auth = getAuth();
const db = getFirestore();

//export 
export { auth, db }