<template>
  <div class="container py-4">
    <template v-if="isReady">
      <div class="p-5 mb-4 bg-light rounded-3">
        <div class="container-fluid py-5">
          <ol class="list-group list-group-numbered p-2">
            <div v-for="project in projects" :key="project.id">
              <h2
                class="display-5 fw-bold my-cursor-clicker pb-2"
                @click="handleClick(project)"
              >
                {{ project.name }}
              </h2>
            </div>
          </ol>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { db } from "@/firebase/config";
import { collection, getDocs } from "firebase/firestore";

// @ is an alias to /src

export default defineComponent({
  name: "Home",
  components: {},

  setup() {
    const router = useRouter();
    const projects = ref([]);

    // load store
    const store = useStore();

    // get user
    const user = computed(() => store.state.user);

    // get readyState
    const isReady = computed(() => store.state.authIsReady);

    // set projectState to null
    store.commit("updateProjectState", null);

    // get firebase projects

    onMounted(() => {
      const colRef = collection(db, "projects");

      getDocs(colRef)
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            projects.value.push({ ...doc.data(), id: doc.id });
          });
        })
        .catch((error) => {
          if (error.code === "permission-denied") {
            router.push({ name: "Login" });
          } else {
            //handle other non-permission errors here
          }
        });
    });

    // update state on project click
    const handleClick = (pj) => {
      // commit to store
      store.commit("updateProjectState", pj);
      // route
      router.push("/show");
    };

    return {
      isReady,
      user,
      projects,
      handleClick,
    };
  },
});
</script>

<style>
.my-cursor-clicker {
  cursor: pointer;
}
</style>
