<template>
  <div class="container py-4">
    <template v-if="isReady">
      <FilterNav @filter="changeCurrent" :By="currentFilter" />

      <div class="p-5 mb-4 bg-light rounded-3">
        <div class="container-fluid py-5">
          <h1 class="display-5 fw-bold">Tasks</h1>
          <p class="p-2 text-secondary">{{ projectState.name }}</p>

          <ol class="list-group list-group-numbered p-2">
            <div v-for="task in filteredTasks" :key="task.id">
              <ListTasks
                :task="task"
                @deleteAction="handleDelete"
                @completeAction="handleComplete"
              />
            </div>
          </ol>
        </div>
      </div>
    </template>
  </div>
</template>
  
  <script>
// @ is an alias to /src
import ListTasks from "@/components/ListTasks.vue";
import FilterNav from "@/components/FilterNav.vue";
import { useRouter } from "vue-router";
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";

// from firebase
import { db } from "@/firebase/config";
import { collection, getDocs, query, where } from "firebase/firestore";

export default {
  name: "ShowDetails",
  components: {
    ListTasks,
    FilterNav,
  },
  setup() {
    const router = useRouter();
    const tasks = ref([]);
    const currentFilter = ref("All");

    // load store
    const store = useStore();
    // grab state
    const projectState = ref(store.state.projectState);
    // get readyState
    const isReady = computed(() => store.state.authIsReady);

    onMounted(() => {
      if (projectState) {

      (async () => {
        try {
          // get firebase projects
          let colRef = collection(db, "tasks");

          colRef = query(colRef, where("project", "==", projectState.value.id));

          await getDocs(colRef).then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              tasks.value.push({ ...doc.data(), id: doc.id });
            });
          });
        } catch (error) {
          if (error.code === "permission-denied") {
            router.push({ name: "Login" });
          } else {
            //handle other non-permission errors here
          }
        }
      })();

      } else {
        router.push("/");
      }
      
    });

    const filteredTasks = computed(() => {
      const stateSet = tasks.value.filter(
        (item) => item.project === projectState.value.id
      ); // this won't filter any task as the query from firestore already filtered tasks specific to this project

      if (currentFilter.value === "Completed") {
        return stateSet.filter((task) => task.complete);
      }

      if (currentFilter.value === "Pending") {
        return stateSet.filter((task) => !task.complete);
      }

      return stateSet;
    });

    const handleDelete = (id) => {
      tasks.value = tasks.value.filter((task) => {
        return task.id !== id;
      });
    };

    const handleComplete = (id) => {
      let t = tasks.value.find((task) => {
        return task.id === id;
      });
      t.complete = !t.complete;
    };

    const changeCurrent = (by) => {
      currentFilter.value = by;
    };

    return {
      isReady,
      tasks,
      currentFilter,
      filteredTasks,
      changeCurrent,
      handleComplete,
      handleDelete,
      projectState,
    };
  },
};
</script>
  