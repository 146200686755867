import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//import vuex store
import store from '@/store/index'

createApp(App).use(router).use(store).mount('#app')
