// broadcastChannel.js
export function sendMessage(message) {
  const channel = new BroadcastChannel('myAppChannel');
  channel.postMessage(message);
  channel.close();
}

export function receiveMessage(callback) {
  const channel = new BroadcastChannel('myAppChannel');  
  channel.onmessage = (event) => {
    callback(event.data);
  };
}