<template>
  <div class="container py-4">
    <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold">Edit Task</h1>

        <form @submit.prevent="handleEdit" class="p-2">
          <div class="mb-3">
            <label for="taskTitle" class="form-label">Title</label>
            <input
              v-model="taskTitle"
              type="text"
              class="form-control"
              id="taskTitle"
            />
          </div>
          <div class="mb-3">
            <label for="taskDetail" class="form-label">Details</label>
            <textarea
              v-model="taskDetails"
              class="form-control"
              id="taskDetail"
              rows="5"
            ></textarea>
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

// firebase
import { db } from "@/firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default {
  name: "EditTask",
  props: ["id"],

  setup(props) {
    const router = useRouter();
    const taskTitle = ref("");
    const taskDetails = ref("");

    // load store
    const store = useStore();
    // grab state
    const projectState = store.state.projectState;

    onMounted(() => {
      if (projectState) {
        // document ID as 3rd variable
        const docRef = doc(db, "tasks", props.id);

        getDoc(docRef)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              // Document exists, retrieve its data
              const data = { ...docSnapshot.data(), id: docSnapshot.id };
              taskTitle.value = data.title;
              taskDetails.value = data.details.replace(/<br\s?\/?>/gi, "\n");
            } else {
              // Document does not exist
              router.push("/");
            }
          })
          .catch((error) => {
            if (error.code === "permission-denied") {
              router.push({ name: "Login" });
            } else {
              //handle other non-permission errors here
            }
          });
      } else {
        router.push("/");
      }
    });

    const handleEdit = async () => {
      let upDatedtaskDetails = taskDetails.value.replace(/\r?\n/g, "<br>");
      let docRef = doc(db, "tasks", props.id);
      await updateDoc(docRef, {
        title: taskTitle.value,
        details: upDatedtaskDetails,
        complete: false,
      });
      router.push("/show");
    };

    return { taskTitle, taskDetails, handleEdit };
  },
};
</script>

<style>
</style>