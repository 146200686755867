<template>
    <div class="container py-4">
    
        <div class="p-5 mb-4 bg-light rounded-3">
          <div class="container-fluid py-5">
            
              <form @submit.prevent="handleSignIn" class="p-2">
                <div class="mb-3">
                  <label for="email" class="form-label">Email</label>
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    id="taskTitle"
                  />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    id="taskTitle"
                  />
                </div>
                <div v-if="error">
                  <p>{{ error }}</p>
                </div>
                <button type="submit" class="btn btn-warning">Sign In</button>
              </form>

          </div>
        </div>

    </div>
  </template>
  
  <script>
  import { defineComponent, ref } from "vue";
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  
  // @ is an alias to /src
  
  export default defineComponent({
    name: "Home",
    components: {},
  
    setup() {
      const router = useRouter();
      const email = ref("");
      const password = ref("");
      const error = ref("");

  
      // load store
      const store = useStore();

      // log user out
      store.dispatch("logOut");
  
      // set projectState to null
      store.commit("updateProjectState", null);
  
      // signin
      const handleSignIn = async () => {
        try {
          await store.dispatch("signIn", {
            email: email.value,
            password: password.value,
          });

          router.push({name: 'Home'});

        } catch (err) {
          error.value = err.message;
        }
  
        email.value = "";
        password.value = "";
      };
  

     
      return {
        error,
        email,
        password,
        handleSignIn,
      };
    },
  });
  </script>
  
  <style>
  .my-cursor-clicker {
    cursor: pointer;
  }
  </style>
  