<template>
  
<footer class="container pt-3 pb-5 mt-4 text-body-secondary border-top">
&copy; 2023
</footer>

</template>

<script>
export default {

}
</script>

<style>

</style>