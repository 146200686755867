<template>
  <ul class="nav nav-tabs justify-content-end mb-4">
    <li class="nav-item">
      <span @click="filterBy('Pending')" class="nav-link my-cursor-clicker" :class="{ active: By === 'Pending'}">Pending</span>
    </li>
    <li class="nav-item">
      <span @click="filterBy('Completed')" class="nav-link my-cursor-clicker" :class="{ active: By === 'Completed'}">Completed</span>
    </li>    
    <li class="nav-item">
      <span @click="filterBy('All')" class="nav-link my-cursor-clicker" :class="{ active: By === 'All'}">Everything</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'FilterNav',
  props: ['By'],
  methods: {
    filterBy(by) {
      this.$emit('filter', by)
    }
  }
};
</script>

<style>
.my-cursor-clicker {
  cursor: pointer;
}
</style>