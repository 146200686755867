import { createStore } from 'vuex'
import { auth } from '@/firebase/config.js'
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged
} from 'firebase/auth'
import VuexPersist from 'vuex-persist'
import { sendMessage, receiveMessage } from '@/broadcast/index';

const vuexPersist = new VuexPersist({
    key: 'uData',
    storage: window.sessionStorage,
    reducer: (state) => ({ user: state.user, projectState: state.projectState })
})

const store = createStore({
    state: {
        user: null,
        projectState: null,
        authIsReady: false,

    },
    plugins: [vuexPersist.plugin],
    mutations: {
        updateProjectState(state, payload) {
            state.projectState = payload;
        },
        newUserState(state, payload) {
            state.user = payload;        
        },
        changeAuthIsReady(state, payload) {
            state.authIsReady = payload;
        }
    },
    actions: {
        async signUp(context, { email, password }) {

            const res = await createUserWithEmailAndPassword(auth, email, password)
            if (res) {
                context.commit('newUserState', res.user);
            } else {
                throw new Error('Unable to Sign Up')
            }

        },

        async signIn(context, { email, password }) {

            const res = await signInWithEmailAndPassword(auth, email, password)
            if (res) {
                context.commit('newUserState', res.user)
            } else {
                throw new Error('Unable to Sign In')
            }

        },

        async logOut(context) {

            await signOut(auth)

            context.commit('newUserState', null)

            sendMessage(null) // Send the updated value to other browser panels

        }

    }
})


const unsub = onAuthStateChanged(auth, (user) => {
    store.commit('changeAuthIsReady', true)
    store.commit('newUserState', user)
    unsub()
})


receiveMessage((newload) => {
    store.commit('newUserState', newload); // Update the value in the store when receiving a message // receiveMessage function is called 1st
});


export default store
