<template>
  <li
    class="list-group-item d-flex justify-content-between align-items-start"
    :class="{ 'bg-info bg-opacity-25': task.complete }"
  >
    <div class="ms-2 me-auto">
      <div
        class="fw-bold my-cursor-clicker pb-3"
        @click="showDetails = !showDetails"
      >
        {{ task.title }}
      </div>
      <p class="me-5" v-if="showDetails" v-html="task.details"></p>
    </div>

    <router-link :to="{ name: 'EditTask', params: { id: task.id } }">
      <i class="large material-icons m-2 my-cursor-clicker">edit</i>
    </router-link>

    <i @click="deleteTask" class="large material-icons m-2 my-cursor-clicker"
      >delete</i
    >

    <i @click="complteTask" class="large material-icons m-2 my-cursor-clicker"
      >done</i
    >
  </li>
</template>

<script>
// firebase
import { db } from "@/firebase/config";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";

export default {
  name: "ListTasks",
  props: ["task"],
  data() {
    return {
      showDetails: false,
      url: "http://localhost:3000/tasks/" + this.task.id,
    };
  },
  methods: {
    deleteTask() {
      let docRef = doc(db, "tasks", this.task.id);
      deleteDoc(docRef);
      this.$emit("deleteAction", this.task.id);
    },
    complteTask() {
      let docRef = doc(db, "tasks", this.task.id);
      updateDoc(docRef, { complete: !this.task.complete });
      this.$emit("completeAction", this.task.id);
    },
  },
};
</script>

<style>
.my-cursor-clicker {
  cursor: pointer;
}

.material-icons {
  color: #aeb6bf;
}

.material-icons:hover {
  color: #808b96;
}

/* Define the variable */
:root {
  --complete-bg: rgba(108, 117, 125, 0.25);
}

/* Use the variable to create the alias */
.complete {
  background-color: #17a2b8;
  background-color: var(--complete-bg);
}
</style>