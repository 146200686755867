<template>
  <header class="container-fluid">
    <div class="container">
      <nav class="navbar navbar-expand-md">
        <div class="container-fluid">
          <router-link class="navbar-brand" to="/">ToDoApp</router-link>

          <template v-if="projectState">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div class="navbar-nav">
                <router-link class="nav-link" to="/show"
                  >Show Tasks</router-link
                >
                <router-link class="nav-link" to="/add">Add Task</router-link>
                <span class="nav-link logout my-cursor-clicker" @click="logOut"
                  >Log Out</span
                >
              </div>
            </div>
          </template>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { defineComponent, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Component (Nav)",

  setup() {
    // grab state
    const router = useRouter();
    const store = useStore();
    

    const logOut = () => {
      router.push({ name: "Login" });
    };

    watchEffect(() => {
      if (!store.state.user) {
        router.push({ name: "Login" });
      }
    });

    const projectState = computed(() => store.state.projectState);
    
    return { 
      projectState, 
      logOut,
      checkAuth: computed(() => store.state.user), };
  },
});
</script>


<style>
a.nav-link.router-link-active {
  border-bottom: 2px solid #cd6155;
}

logout {
  border-bottom: 2px solid #cd6155;
}

.my-cursor-clicker {
  cursor: pointer;
}
</style>