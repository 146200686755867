import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import AddTask from '../views/AddTask.vue'
import ShowTasks from '../views/ShowTasks.vue'
import EditTask from '../views/EditTask.vue'
import Login from '../views/Login.vue'

// authentication routing
import store from '../store/index'

// Routing guide
const authRequired = (to, from, next) => {

  const authReport = store.state.user;

  if (authReport) {
    // Move on
    next();
  } else {
    // Redirect
    next({ name: 'Login' })
  }
  
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: authRequired
  },
  {
    path: '/add',
    name: 'AddTask',
    component: AddTask,
    beforeEnter: authRequired
  },
  {
    path: '/show',
    name: 'ShowTasks',
    component: ShowTasks,
    beforeEnter: authRequired
  },
  {
    path: '/edit/:id',
    name: 'EditTask',
    component: EditTask,
    props: true,
    beforeEnter: authRequired
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
