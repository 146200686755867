<template>

<Nav />  
<router-view/>
<Footer />
  
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Nav,
    Footer,
  }
}
 </script> 

<style>

</style>
